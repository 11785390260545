<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableList'
import { downloadAction, getAction, getParams } from '@/command/netTool'
import Big from 'big.js'

export default {
  name: 'ddmx',
  data() {
    return {
      records: [],
      totalData: null,
      current: 1,
      total: 1,
      // listUrl: '/reportForm/getMerchantReportPage',
      params: { current: 1, pageSize: 10 },
      paramsValue: {},
      businessIdList: [],
      headerTypeData: {
        fromType: [
          {
            name: '农家特产',
            value: '1',
          },
          {
            name: '农家乐',
            value: '2',
          },
          {
            name: '农事体验',
            value: '4',
          },
          {
            name: '酒店民宿',
            value: '13',
          },
          {
            name: '景点门票',
            value: '12',
          },
          {
            name: '组合套餐',
            value: '14',
          },
        ],
        status: [
          {
            name: '待使用',
            value: '1',
          },
          {
            name: '待发货',
            value: '2',
          },
          {
            name: '待签收',
            value: '3',
          },
          {
            name: '待评价',
            value: '4',
          },
          {
            name: '已完成',
            value: '5',
          },
          {
            name: '已关闭',
            value: '6',
          },
        ],
        businessId: [],
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getTotal(params) {
      return new Promise((resolve) => {
        // if (this.totalData) {
        //   resolve(this.totalData)
        // } else {
        this.params = {
          ...this.params,
          ...params,
        }
        getAction('/reportForm/getOrderDetailReportCount', params).then((e) => {
          this.totalData = e.data
          resolve(e.data)
        })
        // }
      })
    },
    getSum(list) {
      let totalAmount = 0
      let productQuantity = 0
      let feeAmount = 0
      let freightAmount = 0

      list.forEach((e) => {
        totalAmount = parseFloat(new Big(e.totalAmount || 0).plus(totalAmount))
        feeAmount = parseFloat(new Big(e.feeAmount || 0).plus(feeAmount))
        freightAmount = parseFloat(new Big(e.freightAmount || 0).plus(freightAmount))
        e.itemList.forEach((ele) => {
          productQuantity = parseFloat(new Big(ele.productQuantity || 0).plus(productQuantity))
        })
      })
      return {
        orderId: '合计',
        totalAmount,
        productQuantity,
        feeAmount,
        freightAmount,
      }
    },
    getList(params = this.params) {
      this.getTotal(params).then((total) => {
        getAction('/reportForm/getOrderDetailReportPage', params).then((e) => {
          this.records = e.data['records'].map((e) => ({ ...e, dateTypeQuery: params.dateTypeQuery }))
          this.params = params
          this.params['current'] = e.data['current']
          this.params['pageSize'] = e.data['pageSize']
          this.current = e.data['current']
          this.total = e.data['total']
          if (this.records.length !== 0) {
            this.records.push(this.getSum(e.data['records']))
            this.records.push({
              ...total,
              orderId: '总计',
            })
          }
        })
      })
    },
    getHeader() {
      return [
        {
          name: '订单ID',
          type: 'input',
          key: 'orderId',
        },
        {
          name: '下单时间',
          type: 'rangePicker',
          keys: ['dateGe', 'dateLe'],
        },
        {
          name: '商品名称',
          type: 'input',
          key: 'productName',
        },
        {
          name: '商户名称',
          type: 'input',
          key: 'businessName',
        },
        {
          name: '购买人',
          type: 'input',
          key: 'buyName',
        },
        {
          name: '手机号',
          type: 'input',
          key: 'buyPhone',
        },
        {
          type: 'select',
          name: '订单状态',
          key: 'status',
        },
        {
          type: 'select',
          name: '商品类型',
          key: 'fromType',
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '订单ID',
          dataIndex: 'orderId',
        },
        {
          title: '商品名称',
          dataIndex: 'productName',
          customRender: (text, records) => {
            return (
              <div>
                {records.itemList.map((item, i) => {
                  return (
                    <div
                      style={{
                        borderTop: i == 0 ? 'none' : '1px solid #e1e1e1',
                        padding:
                          i == 0 ? (records.itemList.length == 1 ? '0px 0 0px 0px' : '0px 0 6px 0px') : '6px 0px',
                      }}
                    >
                      {item.productName}
                    </div>
                  )
                })}
              </div>
            )
          },
        },
        {
          title: '商户名称',
          dataIndex: 'businessName',
        },
        {
          title: '购买人',
          dataIndex: 'buyName',
        },
        {
          title: '手机号',
          dataIndex: 'buyPhone',
        },
        {
          title: '下单时间',
          dataIndex: 'createTime',
        },
        {
          title: '数量',
          dataIndex: 'productQuantity',
          customRender: (text, records) => {
            return (
              <div>
                {records.itemList.map((item, i) => {
                  return (
                    <div
                      style={{
                        borderTop: i == 0 ? 'none' : '1px solid #e1e1e1',
                        padding:
                          i == 0 ? (records.itemList.length == 1 ? '0px 0 0px 0px' : '0px 0 6px 0px') : '6px 0px',
                      }}
                    >
                      {item.productQuantity}
                    </div>
                  )
                })}
              </div>
            )
          },
        },
        {
          title: '支付金额',
          dataIndex: 'totalAmount',
        },
        {
          title: '运费',
          dataIndex: 'freightAmount',
          customRender: (text, records) => {
            return text || '0'
          },
        },
        {
          title: '手续费',
          dataIndex: 'feeAmount',
          customRender: (text, records) => {
            return text || '0'
          },
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text, records) => {
            if (records.pickUpId && text == 2) {
              return '待取货'
            }
            return this.headerTypeData.status.find((d) => d.value == text).name
          },
        },
      ]
    },
    onChange(data, filter) {
      this.getList(data)
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            const { dateGe, dateLe, fromType, productName } = this.params
            const exportParams = { ...this.params }
            delete exportParams.current
            delete exportParams.pageSize
            delete exportParams.size
            let urlParam = ''

            const keys = Object.keys(exportParams)
            keys.forEach((ele) => {
              if (exportParams[ele]) {
                urlParam = urlParam + `${ele}=${exportParams[ele]}&`
              }
            })

            if (this.businessIdList.length > 0) {
              urlParam = urlParam ? urlParam + `&ids=${this.businessIdList}` : `ids=${this.businessIdList}`
            }

            downloadAction(`/api/reportForm/exportOrderDetailReportForm?${urlParam}`, '订单明细.xlsx')
          },
        },
      ]
    },
    selectRow(data, records) {
      this.businessIdList = records.map((d) => d.orderId)
    },
  },
  render() {
    return (
      <TemplateHeaderTableNew
        ref={'table'}
        records={this.records}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        rightButton={this.getRightButton()}
        button={[]}
        filterRightButtonKey={['refresh', 'setting']}
        onChange={this.onChange}
        headerTypeData={this.headerTypeData}
        onSelectRow={this.selectRow}
      />
    )
  },
}
</script>
